@media (min-width: 767px) {
    .text-container h1 {
      font-size: 2.375rem;
    }
  }
  
  @media (min-width: 767px) {
    .text-container div {
      font-size: 2.375rem;
    }
  }
  
  @media (max-width: 766px) {
    .text-container div {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 766px) {
    .text-container h1 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .text-container div {
      font-size: 1.3rem;
    }
  }
  
  @media (max-width: 480px) {
    .text-container h1 {
      font-size: 1.3rem;
    }
  }
  
  /* @media (max-width: 480px) {
    .text-container div {
      font-size: 20
    }
  } */
  